import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const ImageBackground = styled("div")`
  margin-top: 64px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(${({ image }) => image});
  @media screen and (max-width: 960px) {
    margin-top: 32px;
  }
`;

const hoverable = (props) =>
  props.handle &&
  css`
    &:hover {
      background: rgba(0, 0, 0, 0.3);
    }
  `;

const CollectionWrapper = styled("div")`
  padding: 32px;
  background: rgba(0, 0, 0, 0.5);
  transition: 300ms;
  @media screen and (max-width: 960px) {
    padding: 12px;
  }
  ${hoverable}
`;

const TitleBox = styled("div")`
  padding: 64px;
  margin: 32px;
  text-align: center;
  color: white;
  border: 5px solid #fff;
  font-family: "Raleway", sans-serif;
  font-size: 2rem;
  @media screen and (max-width: 960px) {
    padding: 32px 64px;
    border: 3px solid #fff;
    font-size: 1.75rem;
  }
`;

const withLink = (handle, collection) => (
  <StyledLink to={`/collections/${handle}`}>
    <CollectionCard handle={handle} {...collection} />
  </StyledLink>
);

const CollectionCard = ({ handle, image, title }) => (
  <ImageBackground image={image}>
    <CollectionWrapper handle={handle}>
      <TitleBox>{title}</TitleBox>
    </CollectionWrapper>
  </ImageBackground>
);

export const Collection = ({ handle, ...collection }) => {
  return handle ? (
    withLink(handle, collection)
  ) : (
    <CollectionCard {...collection} />
  );
};

import { gql, useQuery } from '@apollo/client';
import { Loader } from '../Loader/Loader.component';
import { SearchComponent } from './Search.component';

const GET_PRODUCTS = gql`
    query searchProducts(
        $title: String
        $after: String
        $before: String
        $first: Int
        $last: Int
    ) {
        products(
            first: $first
            last: $last
            before: $before
            after: $after
            query: $title
        ) {
            pageInfo {
                hasNextPage
                hasPreviousPage
            }
            edges {
                cursor
                node {
                    id
                    title
                    description
                    totalInventory
                    variants(first: 10) {
                        edges {
                            node {
                                price {
                                    amount
                                }
                                image {
                                    id
                                    originalSrc
                                }
                            }
                        }
                    }
                    images(first: 10) {
                        edges {
                            node {
                                id
                                originalSrc
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const Search = ({
    match: {
        params: { query: title, direction = 'after', cursor },
    },
}) => {
    const { data } = useQuery(GET_PRODUCTS, {
        variables: {
            title,
            ...(direction === 'after'
                ? { after: cursor, first: 12 }
                : { before: cursor, last: 12 }),
        },
    });

    if (data && data?.products?.edges) {
        const len = data.products.edges.length;
        const productsData = {
            products: data.products.edges.map(
                ({
                    node: {
                        id,
                        title,
                        description,
                        totalInventory,
                        images,
                        variants,
                    },
                }) => ({
                    id,
                    title,
                    description,
                    totalInventory,
                    images: images.edges.map(({ node }) => node?.originalSrc),
                    variants: variants.edges.map(
                        ({
                            node: {
                                price: { amount },
                                selectedOptions,
                            },
                        }) => ({
                            amount,
                            selectedOptions,
                        })
                    ),
                })
            ),
            pageInfo: data.products.pageInfo,
            before: data.products.edges[0]?.cursor,
            after: data.products.edges[len - 1]?.cursor,
        };
        return <SearchComponent query={title} {...productsData} />;
    }

    return <Loader />;
};

/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import Modal from "@material-ui/core/Modal";

import "./ProductDetails.css";
import { useState } from "react";
import { ContactForm } from "./ContactForm.component";

const ShowDesktop = styled("div")`
  display: flex;
  @media screen and (max-width: 960px) {
    display: none;
  }
`;

const ShowMobile = styled("div")`
  padding: 0 8px;
  margin-bottom: 64px;
  @media screen and (min-width: 960px) {
    display: none;
  }
`;

export const ProductPresenter = ({
  title,
  descriptionHtml,
  totalInventory,
  images,
  variants,
}) => {
  console.log(variants);
  const [variant, setVariant] = useState(variants[0]);
  const [openModal, setOpenModal] = useState(false);

  return (
    <div css={{ display: "flex", paddingTop: 32, minHeight: 588 }}>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <ContactForm
          onClose={() => setOpenModal(false)}
          title={title}
          variant={variants.length === 1 ? "" : variant.selectedOptions}
        />
      </Modal>
      <ShowDesktop>
        <div css={{ flex: "1 0 40%", paddingRight: 32 }}>
          <Carousel infiniteLoop showArrows swipeable emulateTouch>
            {images?.map((image) => (
              <img key={image} src={image} alt="Product" />
            ))}
          </Carousel>
        </div>
        <div css={{ flex: "1 0 40%", paddingLeft: 32 }}>
          <h2
            css={{
              fontSize: "1.5rem",
              marginLeft: 16,
              fontWeight: 500,
              fontFamily: "'Poppins', sans-serif",
              margin: 0,
            }}
          >
            {title}
          </h2>
          <div
            css={{
              fontFamily: "'Poppins', sans-serif",
            }}
            dangerouslySetInnerHTML={{ __html: descriptionHtml }}
          />
          {variants.length > 1 && (
            <FormControl
              variant="outlined"
              css={{
                marginTop: "32px !important",
                width: "100%",
              }}
            >
              <InputLabel htmlFor="outlined-age-native-simple">
                Style
              </InputLabel>
              <Select
                native
                // value={state.Style}
                onChange={(val) =>
                  setVariant(
                    variants.find(
                      (variant) =>
                        variant.selectedOptions === val.currentTarget.value
                    )
                  )
                }
                placeholder="Select Style"
                label="Style"
                inputProps={{
                  name: "Style",
                  id: "outlined-age-native-simple",
                }}
              >
                {variants.map((variant) => (
                  <option
                    key={variant.selectedOptions}
                    value={variant.selectedOptions}
                  >
                    {variant.selectedOptions}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}

          <div
            css={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 16,
              alignItems: "center",
            }}
          >
            <span
              css={{
                textAlign: "center",
                fontWeight: 500,
                fontFamily: "'Poppins', sans-serif",
                ...(!variant.quantityAvailable && { color: "red" }),
              }}
            >
              {variant.quantityAvailable
                ? `${variant.quantityAvailable} left in stock.`
                : "SOLD OUT"}
            </span>
            <Button
              variant="outlined"
              size="medium"
              color="primary"
              onClick={() => setOpenModal(true)}
            >
              Contact for details
            </Button>
          </div>
        </div>
      </ShowDesktop>
      <ShowMobile>
        <h2
          css={{
            fontSize: "1.5rem",
            marginLeft: 16,
            fontWeight: 500,
            fontFamily: "'Poppins', sans-serif",
            margin: 0,
          }}
        >
          {title}
        </h2>
        <Carousel infiniteLoop showArrows swipeable emulateTouch>
          {images?.map((image) => (
            <img key={image} src={image} alt="Product" />
          ))}
        </Carousel>
        <div
          css={{
            fontFamily: "'Poppins', sans-serif",
          }}
          dangerouslySetInnerHTML={{ __html: descriptionHtml }}
        />
        {variants.length > 1 && (
          <FormControl
            variant="outlined"
            css={{
              marginTop: "32px !important",
              width: "100%",
            }}
          >
            <InputLabel htmlFor="outlined-age-native-simple">Style</InputLabel>
            <Select
              native
              // value={state.Style}
              onChange={(val) =>
                setVariant(
                  variants.find(
                    (variant) =>
                      variant.selectedOptions === val.currentTarget.value
                  )
                )
              }
              placeholder="Select Style"
              label="Style"
              inputProps={{
                name: "Style",
                id: "outlined-age-native-simple",
              }}
            >
              {variants.map((variant) => (
                <option
                  key={variant.selectedOptions}
                  value={variant.selectedOptions}
                >
                  {variant.selectedOptions}
                </option>
              ))}
            </Select>
          </FormControl>
        )}
        <div
          css={{
            textAlign: "center",
            fontWeight: 500,
            fontFamily: "'Poppins', sans-serif",
            ...(!variant.quantityAvailable && { color: "red" }),
          }}
        >
          {variant.quantityAvailable
            ? `${variant.quantityAvailable} left in stock.`
            : "SOLD OUT"}
        </div>
        <div css={{ display: "flex", justifyContent: "center", marginTop: 8 }}>
          <Button
            variant="outlined"
            size="medium"
            color="primary"
            onClick={() => setOpenModal(true)}
          >
            Contact for details
          </Button>
        </div>
      </ShowMobile>
    </div>
  );
};

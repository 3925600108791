import { gql, useQuery } from '@apollo/client';
import { Loader } from '../Loader/Loader.component';
import { ProductsPresenter } from './Products.component';

const GET_PRODUCTS = gql`
    query getProducts(
        $collectionHandle: String!
        $after: String
        $before: String
        $first: Int
        $last: Int
    ) {
        collectionByHandle(handle: $collectionHandle) {
            id
            title
            handle
            image {
                originalSrc
            }
            products(
                first: $first
                last: $last
                after: $after
                before: $before
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                }
                edges {
                    cursor
                    node {
                        id
                        title
                        description
                        totalInventory
                        variants(first: 10) {
                            edges {
                                node {
                                    price {
                                        amount
                                    }
                                    image {
                                        id
                                        originalSrc
                                    }
                                }
                            }
                        }
                        images(first: 10) {
                            edges {
                                node {
                                    id
                                    originalSrc
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const Products = ({
    match: {
        params: { collectionHandle, direction = 'after', cursor },
    },
}) => {
    const { data } = useQuery(GET_PRODUCTS, {
        variables: {
            collectionHandle,
            ...(direction === 'after'
                ? { after: cursor, first: 12 }
                : { before: cursor, last: 12 }),
        },
    });

    if (data && data?.collectionByHandle.products?.edges?.length) {
        const len = data.collectionByHandle.products.edges.length;
        const productsData = {
            collection: {
                title: data.collectionByHandle.title,
                image: data.collectionByHandle.image?.originalSrc,
                handle: data.collectionByHandle.handle,
            },
            products: data.collectionByHandle.products.edges.map(
                ({
                    node: {
                        id,
                        title,
                        description,
                        totalInventory,
                        images,
                        variants,
                    },
                }) => ({
                    id,
                    title,
                    description,
                    totalInventory,
                    images: images.edges.map(({ node }) => node?.originalSrc),
                    variants: variants.edges.map(
                        ({
                            node: {
                                price: { amount },
                                selectedOptions,
                            },
                        }) => ({
                            amount,
                            selectedOptions,
                        })
                    ),
                })
            ),
            pageInfo: data.collectionByHandle.products.pageInfo,
            before: data.collectionByHandle.products.edges[0].cursor,
            after: data.collectionByHandle.products.edges[len - 1].cursor,
        };
        return <ProductsPresenter {...productsData} />;
    }

    return <Loader />;
};

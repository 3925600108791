import "./App.css";
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { Collections } from "./components/Collections/Collections.container";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { Products } from "./components/Products/Products.container";
import styled from "@emotion/styled";
import { Nav } from "./components/Nav/Nav.component";
import { ProductDetails } from "./components/ProductDetails/ProductDetails.container";
import { Search } from "./components/Search/Search.container";
import { Footer } from "./components/Footer/Footer.component";

const httpLink = createHttpLink({
  uri: "https://kevinbear.myshopify.com/api/2021-01/graphql.json",
});

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    Accept: "application/json",
    "Content-Type": "application/graphql",
    // these are not secrets
    "X-Shopify-Storefront-Access-Token": "f7fad0e7338a655623005d0b16b46ee9",
  },
}));

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const PageWrapper = styled("div")`
  margin: auto;
  height: 100%;
  @media screen and (min-width: 1200px) {
    max-width: 1200px;
  }
`;

function App() {
  return (
    <ApolloProvider client={client}>
      <Router>
        <Nav />
        <PageWrapper>
          <Switch>
            <Route path="/product/:productTitle" component={ProductDetails} />
            <Route
              path="/collections/:collectionHandle/:direction?/:cursor?"
              component={Products}
            />
            <Route
              path="/search/:query/:direction?/:cursor?"
              component={Search}
            />
            <Route path="/" component={Collections} />
          </Switch>
        </PageWrapper>
        <Footer />
      </Router>
    </ApolloProvider>
  );
}

export default App;

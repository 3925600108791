/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { Collection } from "../Collections/Collection.component";
import { Product } from "./Product.component";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #000;
`;

export const ProductsPresenter = ({
  collection,
  products,
  before,
  after,
  pageInfo,
}) => {
  console.log(pageInfo);
  return (
    <div>
      <Collection {...collection} />
      <div
        css={{
          marginTop: 32,
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-start",
        }}
      >
        {products.map(({ id, ...product }) => (
          <Product key={id} {...product} />
        ))}
      </div>
      <div css={{ display: "flex", marginBottom: 32 }}>
        {pageInfo.hasPreviousPage && (
          <StyledLink to={`/collections/${collection.handle}/before/${before}`}>
            <NavigateBeforeIcon
              css={{ width: "50px !important", height: "50px !important" }}
            />
          </StyledLink>
        )}
        <div css={{ flexGrow: 1 }} />
        {pageInfo.hasNextPage && (
          <StyledLink to={`/collections/${collection.handle}/after/${after}`}>
            <NavigateNextIcon
              css={{ width: "50px !important", height: "50px !important" }}
            />
          </StyledLink>
        )}
      </div>
    </div>
  );
};

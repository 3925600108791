import { gql, useQuery } from "@apollo/client";
import { Loader } from "../Loader/Loader.component";
import { CollectionsPresenter } from "./Collections.component";

const GET_COLLECTIONS = gql`
  query getCollections {
    collections(first: 10) {
      edges {
        node {
          id
          title
          handle
          image {
            id
            originalSrc
            transformedSrc
          }
        }
      }
    }
  }
`;

export const Collections = () => {
  const { data } = useQuery(GET_COLLECTIONS);

  if (data) {
    const collections = data.collections.edges.map(({ node }) => ({
      id: node.id,
      title: node.title,
      image: node.image?.originalSrc,
      handle: node.handle,
    }));
    return <CollectionsPresenter collections={collections} />;
  }
  return <Loader />;
};

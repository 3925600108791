import { gql, useQuery } from '@apollo/client';
import { Loader } from '../Loader/Loader.component';
import { ProductPresenter } from './ProductDetails.component';

const GET_PRODUCT = gql`
    query getProduct($title: String) {
        products(first: 1, query: $title) {
            edges {
                node {
                    title
                    descriptionHtml
                    totalInventory
                    variants(first: 10) {
                        edges {
                            node {
                                price {
                                    amount
                                }
                                quantityAvailable
                                selectedOptions {
                                    name
                                    value
                                }
                                image {
                                    id
                                    originalSrc
                                }
                            }
                        }
                    }
                    images(first: 10) {
                        edges {
                            node {
                                id
                                originalSrc
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const ProductDetails = ({
    match: {
        params: { productTitle },
    },
}) => {
    const { loading, data } = useQuery(GET_PRODUCT, {
        variables: {
            title: productTitle.replaceAll('PERCENT', '%'),
        },
    });

    if (!loading && data?.products?.edges?.length) {
        const { title, descriptionHtml, totalInventory, images, variants } =
            data.products.edges[0].node;
        const productsData = {
            title,
            descriptionHtml,
            totalInventory,
            images: images.edges.map(({ node }) => node?.originalSrc),
            variants: variants.edges.map(
                ({
                    node: {
                        price: { amount },
                        selectedOptions,
                        quantityAvailable,
                    },
                }) => ({
                    amount,
                    quantityAvailable,
                    selectedOptions: selectedOptions
                        .map((option) => option.value)
                        .join(', '),
                })
            ),
        };

        return <ProductPresenter {...productsData} />;
    }
    return <Loader />;
};

/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import InstagramIcon from "@material-ui/icons/Instagram";

const FooterWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 64px 0;
  margin: auto 8px;
  @media screen and (min-width: 1200px) {
    max-width: 1200px;
    margin: auto;
  }
  & a {
    color: #fff;
    text-decoration: none;
  }
  fontfamily: "'Poppins', sans-serif";
`;

const PageWrapper = styled("div")`
  background-color: #000;
  color: #fff;
`;

const LinkWithLogo = styled("a")`
  display: flex;
  align-items: center;
  @media screen and (max-width: 960px) {
    margin-top: 8px;
    flex: 1 0 100%;
  }
`;

const Flex = styled("div")`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 960px) {
    flex-wrap: wrap;
  }
`;

export const Footer = () => {
  return (
    <PageWrapper>
      <FooterWrapper>
        <Flex>
          <span>{`Bearbrick Canada © ${new Date().getFullYear()}`}</span>
          <LinkWithLogo href="https://www.instagram.com/bearbrickcanada_/">
            <InstagramIcon css={{ marginRight: 8 }} />
            @bearbrickcanada_
          </LinkWithLogo>
        </Flex>
      </FooterWrapper>
    </PageWrapper>
  );
};

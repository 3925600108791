/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import SendIcon from "@material-ui/icons/Send";
import { TextField } from "@material-ui/core";
import { useState } from "react";

const NavWrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin: auto 8px;
  @media screen and (min-width: 1200px) {
    max-width: 1200px;
    margin: auto;
  }
  & a {
    color: #fff;
    text-decoration: none;
  }
  font-weight: 500;
  fontfamily: "'Poppins', sans-serif";
`;

const PageWrapper = styled("div")`
  background-color: #000;
  color: #fff;
  height: 50px;
  position: sticky;
  top: 0;
  z-index: 10;
`;

const StyledInput = styled(TextField)`
  width: 0;
  &.open {
    width: 150px;
  }
  transition: 250ms;
  & .MuiInput-underline:hover:not(.Mui-disabled)::before {
    border-bottom: 2px solid #fff;
  }
  & .MuiInput-underline::before {
    border-bottom: 1px solid #fff;
  }
  & .MuiInputBase-input {
    color: #fff;
  }
`;

export const Nav = () => {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [searchInput, setSearchInput] = useState();

  return (
    <PageWrapper>
      <NavWrapper>
        <div>
          <Link to="/">Bearbrick Canada</Link>
        </div>
        <div
          css={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <StyledInput
            size="small"
            className={open ? "open" : ""}
            inputRef={(input) => input && setSearchInput(input)}
            value={query}
            onChange={(e) => setQuery(e.currentTarget.value)}
          />
          {query ? (
            <Link
              to={`/search/${query}`}
              onClick={() => {
                setOpen(false);
                setQuery("");
              }}
            >
              <SendIcon css={{ marginLeft: 8 }} />
            </Link>
          ) : (
            <SearchIcon
              onClick={() => {
                setOpen(!open);
                if (open) {
                  searchInput.blur();
                } else {
                  searchInput.focus();
                }
              }}
            />
          )}
        </div>
      </NavWrapper>
    </PageWrapper>
  );
};

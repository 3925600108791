import { gql, useQuery } from "@apollo/client";
import styled from "@emotion/styled";
import parse from "html-react-parser";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const GET_BANNER_IMAGES = gql`
  query getBannerImages {
    blogs(first: 1, query: "title:Images") {
      edges {
        node {
          articles(first: 1) {
            edges {
              node {
                contentHtml
              }
            }
          }
        }
      }
    }
  }
`;

const StyledCarousel = styled(Carousel)`
  & .carousel .slide img {
    max-width: 100%;
    max-height: 400px;
    object-fit: cover;
    overflow: hidden;
  }
  & .carousel.carousel-slider .control-prev.control-arrow::before {
    border-right: 8px solid #fff;
  }
  & .carousel.carousel-slider .control-next.control-arrow::before {
    border-left: 8px solid #fff;
  }
  @media screen and (max-width: 960px) {
    & .carousel .slide img {
      max-height: 250px;
    }
  }
`;

export const Banner = () => {
  const { data } = useQuery(GET_BANNER_IMAGES);
  if (data) {
    return (
      <StyledCarousel
        showStatus={false}
        showThumbs={false}
        autoplay
        infiniteLoop
        showArrows
        swipeable
        emulateTouch
      >
        {parse(data.blogs.edges[0].node.articles.edges[0].node.contentHtml)}
      </StyledCarousel>
    );
  }
  return <div />;
};

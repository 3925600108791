/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { Product } from "../Products/Product.component";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #000;
`;

export const SearchComponent = ({
  query,
  products,
  before,
  after,
  pageInfo,
}) => {
  return (
    <div>
      <p
        css={{
          margin: "32px 0",
          textAlign: "center",
          fontSize: "1.5rem",
          fontWeight: 500,
          fontFamily: "'Poppins', sans-serif",
        }}
      >
        {`Search Results for "${query}"`}
      </p>
      <div
        css={{
          marginTop: 32,
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-start",
        }}
      >
        {products?.length ? (
          products.map(({ id, ...product }) => (
            <Product key={id} {...product} />
          ))
        ) : (
          <div
            css={{
              minHeight: 588,
              width: "100%",
            }}
          >
            <p
              css={{
                textAlign: "center",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              No results :(
            </p>
            <p
              css={{
                textAlign: "center",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Return <Link to="/">Home</Link>
            </p>
          </div>
        )}
      </div>
      <div css={{ display: "flex", marginBottom: 32 }}>
        {pageInfo.hasPreviousPage && (
          <StyledLink to={`/search/${query}/before/${before}`}>
            <NavigateBeforeIcon
              css={{ width: "50px !important", height: "50px !important" }}
            />
          </StyledLink>
        )}
        <div css={{ flexGrow: 1 }} />
        {pageInfo.hasNextPage && (
          <StyledLink to={`/search/${query}/after/${after}`}>
            <NavigateNextIcon
              css={{ width: "50px !important", height: "50px !important" }}
            />
          </StyledLink>
        )}
      </div>
    </div>
  );
};

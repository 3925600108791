/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import { useState } from "react";

const ModalContainer = styled("div")`
  background-color: #fff;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 32px;
  max-width: 700px;
`;

const emailIsValid = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

const onSubmit = async ({
  contact,
  details,
  title,
  variant,
  onClose,
  setError,
  subject: emailSubject,
  setLoading,
}) => {
  if (!contact || !emailIsValid(contact)) {
    setError(true);
    return;
  }
  setLoading(true);
  const text = `Email: ${contact}
Product Title: ${title}
Style: ${variant}
Details: ${details}`;
  const subject = emailSubject || "New Order Inquiry";
  await fetch(
    "https://dio21weae2.execute-api.us-east-2.amazonaws.com/default/KevinBearbrick",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ text, subject, to: "bearbrickcanada1@gmail.com" }),
    }
  );
  setLoading(false);
  onClose();
};

const RotatingAutorenewIcon = styled(AutorenewIcon)`
  & {
    animation: spin 1s linear infinite;
  }
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

export const ContactForm = ({ onClose, title, variant }) => {
  const [error, setError] = useState(false);
  const [contact, setContact] = useState();
  const [subject, setSubject] = useState();
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false);

  return (
    <ModalContainer>
      <div css={{ marginBottom: 16 }}>
        <TextField
          type="email"
          variant="outlined"
          label="Email"
          css={{ width: "100%" }}
          error={error}
          onChange={(e) => {
            setError(!e.currentTarget.value);
            setContact(e.currentTarget.value);
          }}
        />
      </div>
      <div css={{ marginBottom: 16 }}>
        <TextField
          variant="outlined"
          label="Subject"
          css={{ width: "100%" }}
          onChange={(e) => {
            setSubject(e.currentTarget.value);
          }}
        />
      </div>
      <div css={{ marginBottom: 16 }}>
        <TextField
          variant="outlined"
          multiline
          rows={4}
          label="Details"
          css={{ width: "100%" }}
          onChange={(e) => setMessage(e.currentTarget.value)}
        />
      </div>
      <span css={{ marginRight: 16 }}>
        <Button
          disabled={loading}
          variant="outlined"
          size="medium"
          color="primary"
          onClick={() =>
            onSubmit({
              title,
              variant,
              setError,
              contact,
              onClose,
              message,
              subject,
              setLoading,
            })
          }
        >
          Submit
          {loading && <RotatingAutorenewIcon />}
        </Button>
      </span>

      <Button size="medium" onClick={onClose}>
        Cancel
      </Button>
    </ModalContainer>
  );
};

/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

const ProductWrapper = styled("div")`
  margin: 0 12px 32px;
  flex: 0 0 calc(25% - 24px);
  @media screen and (max-width: 960px) {
    flex: 0 0 calc(50% - 24px);
  }
`;

const ProductImage = styled("div")`
  height: 300px;
  display: flex;
  justifycontent: center;
  position: relative;
  @media screen and (max-width: 960px) {
    height: 200px;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const Image = styled("div")`
  padding: 50%;
  position: absolute;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(${(props) => props.src});
  background-size: contain;
`;

export const Product = ({ title, description, totalInventory, images }) => {
  console.log("TITLE", `/product/${title.replaceAll("%", "PERCENT")}`);
  return (
    <ProductWrapper>
      <StyledLink to={`/product/${title.replaceAll("%", "PERCENT")}`}>
        <div
          css={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ProductImage>
            <Image src={images[0]} />
            {!totalInventory && (
              <div
                css={{
                  color: "#fff",
                  position: "absolute",
                  bottom: 0,
                  padding: 20,
                  background: "rgba(0,0,0,0.25)",
                  left: 0,
                  right: 0,
                  textAlign: "center",
                  fontWeight: 700,
                  fontFamily: "'Poppins', sans-serif",
                }}
              >
                Sold Out
              </div>
            )}
          </ProductImage>
          <span css={{ marginTop: 8, textAlign: "center", color: "#000" }}>
            {title}
          </span>
        </div>
      </StyledLink>
    </ProductWrapper>
  );
};

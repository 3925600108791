/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { Banner } from "../Banner/Banner.container";
import { Collection } from "./Collection.component";

const ContentWrapper = styled("div")`
  padding-top: 64px;

  @media screen and (max-width: 960px) {
    padding-top: 0;
  }
`;

const CollectionsWrapper = styled("div")`
  margin-bottom: 64px;

  @media screen and (max-width: 960px) {
    margin-bottom: 32px;
  }
`;

export const CollectionsPresenter = ({ collections }) => {
  return (
    <ContentWrapper>
      <Banner />
      <CollectionsWrapper>
        {collections.map(({ id, ...collection }) => (
          <Collection key={id} {...collection} />
        ))}
      </CollectionsWrapper>
    </ContentWrapper>
  );
};
